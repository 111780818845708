exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-companies-js": () => import("./../../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-currency-converter-js": () => import("./../../../src/pages/CurrencyConverter.js" /* webpackChunkName: "component---src-pages-currency-converter-js" */),
  "component---src-pages-getint-count-number-js": () => import("./../../../src/pages/getintCountNumber.js" /* webpackChunkName: "component---src-pages-getint-count-number-js" */),
  "component---src-pages-int-count-rates-js": () => import("./../../../src/pages/intCountRates.js" /* webpackChunkName: "component---src-pages-int-count-rates-js" */),
  "component---src-pages-investors-js": () => import("./../../../src/pages/investors.js" /* webpackChunkName: "component---src-pages-investors-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-testpage-js": () => import("./../../../src/pages/Testpage.js" /* webpackChunkName: "component---src-pages-testpage-js" */),
  "component---src-pages-tv-js": () => import("./../../../src/pages/TV.js" /* webpackChunkName: "component---src-pages-tv-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-investment-js": () => import("./../../../src/templates/investment.js" /* webpackChunkName: "component---src-templates-investment-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

