import React, { createContext, useContext, useState } from 'react';

const InvestContext = createContext();

export const useInvest = () => {
  return useContext(InvestContext);
};

export const InvestProvider = ({ children }) => {
  const [isInvestButtonVisible, setIsInvestButtonVisible] = useState(true);

  return (
    <InvestContext.Provider value={{ isInvestButtonVisible, setIsInvestButtonVisible }}>
      {children}
    </InvestContext.Provider>
  );
};