// utils/IntCountContext.js
import React, { createContext, useContext, useState } from 'react';

// Create a context for intCount (this should be outside the provider function)
const IntCountContext = createContext();

// Custom hook to use IntCountContext
export const useIntCount = () => {
  return useContext(IntCountContext);
};

// Context provider component
export const IntCountProvider = ({ children }) => {
  const [intCountInvest, setIntCountInvest] = useState(0);

  const value = {
    intCountInvest,
    setIntCountInvest,
  };

  return (
    <IntCountContext.Provider value={value}>
      {children}
    </IntCountContext.Provider>
  );
};
